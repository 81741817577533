import React, { createContext, Reducer } from 'react';
import { IAuthClient } from 'src/services/IAuthClient';
import { TRules } from 'src/interfaces/TRules';
import { IUser } from 'src/interfaces/IUser';
import { ILoginParams } from 'src/components/LoginForm/LoginForm.types';
import { getCookie, setCookie } from 'src/utils/cookie';

interface IAuthState {
  user: IUser | null;
  token: string | null;
  exp: number;
  selectedHotelId: number;
  otp_client_challenge?: string | null;
  otp_sent?: boolean;
}

type TAction =
  | {
      type: 'logged_in';
      payload: { user: IUser; token: string; exp: number; selectedHotelId: number; x_device_bf?: string | null };
    }
  | { type: 'logged_out' }
  | { type: 'switch_hotel'; payload: { hotelId: number } }
  | { type: 'verify_by_email_code'; payload: { otp_sent: boolean; otp_client_challenge: string } };

export type TAuth = IAuthState & {
  isAuthenticated: boolean;
  _client: IAuthClient;
  login: (params: ILoginParams) => Promise<void>;
  logout: () => Promise<void>;
  switchHotel: (hotelId: number) => void;
  hasAccess: (rule: TRules) => boolean;
  resetPassword: (email: string) => Promise<void>;
};

const initialAuthState: IAuthState = {
  user: null,
  token: null,
  exp: -1,
  selectedHotelId: -1,
  otp_client_challenge: null,
  otp_sent: false,
};

const authReducer: Reducer<IAuthState, TAction> = (state, action) => {
  if (action.type === 'logged_in') {
    if (action.payload.x_device_bf) {
      setCookie('x-device-bf', action.payload.x_device_bf, 60);
    }
    return { ...state, ...action.payload };
  }

  if (action.type === 'verify_by_email_code') {
    return { ...state, ...action.payload };
  }

  if (action.type === 'logged_out') {
    return { ...initialAuthState };
  }

  if (action.type === 'switch_hotel') {
    return { ...state, selectedHotelId: action.payload.hotelId };
  }

  return state;
};

export const AuthContext = createContext<TAuth | undefined>(undefined);

export const AuthProvider: React.FC<{ authClient: IAuthClient }> = ({ children, authClient }) => {
  const storedSession = authClient.getSession();
  const selectedHotelId = authClient.getSelectedHotelId();

  const [state, dispatch] = React.useReducer(authReducer, {
    user: storedSession?.user || null,
    token: storedSession?.token || null,
    exp: storedSession?.exp || -1,
    selectedHotelId,
    otp_client_challenge: null,
    otp_sent: false,
  });

  const isAuthenticated = !!state.user;
  const otp_sent = state.otp_sent;

  const login = async (params: ILoginParams) => {
    if (params.otp && state.otp_client_challenge) {
      params.otp_client_challenge = state.otp_client_challenge;
    }

    if (getCookie('x-device-bf')) {
      params.x_device_bf = getCookie('x-device-bf');
    }

    const response = await authClient.login(params);

    if (response.errors) throw new Error(response.errors);

    const { otp_client_challenge, otp_sent, user, token, exp, selectedHotelId, x_device_bf } = response;

    if (otp_client_challenge && otp_sent) {
      dispatch({
        type: 'verify_by_email_code',
        payload: { otp_client_challenge, otp_sent },
      });
      return;
    } else if (user && token && exp) {
      const payload = {
        user,
        token,
        exp,
        selectedHotelId: selectedHotelId || -1,
        x_device_bf,
      };
      dispatch({ type: 'logged_in', payload });
    }
  };

  const logout = async () => {
    authClient.logout();
    dispatch({ type: 'logged_out' });
  };

  const switchHotel = (hotelId: number) => {
    authClient.setSelectedHotelId(hotelId);
    dispatch({ type: 'switch_hotel', payload: { hotelId } });
  };

  const hasAccess = (rule: TRules) => {
    return authClient.hasAccess(rule);
  };

  const resetPassword = async (email: string) => {
    await authClient.resetPassword(email);
  };

  const value: TAuth = {
    ...state,
    isAuthenticated,
    otp_sent,
    _client: authClient,
    login,
    logout,
    switchHotel,
    hasAccess,
    resetPassword,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const AuthConsumer = AuthContext.Consumer;
